import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './style.css';
import Button from '../../components/Forms/Button/Button';

export default function BlockLayout({
  icon,
  id,
  title,
  sub,
  bgColor,
  style,
  children,
  btnLabel,
  handleClick,
  href = null,
  isTitleH3,
  isTitileH2,
}) {
  const breakpoints = useBreakpoint();
  return (
    <div
      id={id}
      className="blockLayuot"
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: !breakpoints.md ? '50%' : '100%',
        backgroundPosition: !breakpoints.md ? '130% 70%' : '-70% 80%',
        backgroundColor: bgColor,
        paddingBottom: 0,
        ...style,
      }}
    >
      <div className="iconBlock">
        <img alt="iconBlock" src={icon} />
      </div>
      <div className="titleBlock">
        <div className="line" />
        {isTitleH3 ? (
          <h3 className="title">{title}</h3>
        ) : isTitileH2 ? (
          <h2 className="title">{title}</h2>
        ) : (
          <div className="title">{title}</div>
        )}
        <div className="line" />
      </div>
      {sub && (
        <div className="subTitile" dangerouslySetInnerHTML={{ __html: sub }} />
      )}

      {children}
      {/* {btnLabel && (
        <Button
          label={btnLabel}
          handleClick={handleClick}
          style={{
            backgroundColor: '#D75F2B',
            color: 'white',
            marginBottom: 30,
          }}
        />
      )} */}
      {href !== null && btnLabel && (
        <a
          target="_blank"
          href={href}
          style={{
            textDecoration: 'none',
            color: 'white',
          }}
          rel="noreferrer"
        >
          <Button
            data-action="cta scopri"
            data-name-cta={btnLabel}
            style={{
              backgroundColor: '#D75F2B',
              color: 'white',
              marginBottom: 30,
              textDecoration: 'none',
              color: 'white',
            }}
          >
            {btnLabel}
          </Button>
        </a>
      )}
    </div>
  );
}
