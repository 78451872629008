/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react';
import './style.css';

import 'mapbox-gl/dist/mapbox-gl.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
// import ReactMapGL, { Marker, FlyToInterpolator } from '!react-map-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import * as mapboxgl from 'mapbox-gl';
import CardMap from './CardMap/CardMap';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Mappa = (features) => {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapCardRef = useRef(null);

  const [mapCard, setMapCard] = useState({});

  useEffect(() => {
    let prev = [];
    if (map.current) return; // initialize map only once
    mapboxgl.accessToken = 'pk.eyJ1IjoibHVjYXZpZGFsZSIsImEiOiJja3dnamw1dGowNjI2Mm9vMDRxYnd6NjAwIn0.evFrNOSTTVD3R7cp6yaOmA';
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/lucavidale/ckwgl5t0g50v814ocfywcf4p3',
      center: [10.46, 40.18],
      zoom: 1.5,
    });
    map.current.scrollZoom.disable();

    mapCardRef.current.style.display = 'none';

    const geojson = {
      type: 'FeatureCollection',
      features: features.features,
    };

    map.current.on('load', () => {
      // Add a GeoJSON source with 3 points.
      map.current.addSource('points', {
        type: 'geojson',
        data: geojson,
      });

      // // add markers to map
    
      // geojson.features.forEach(function(marker) {

      //     // create a HTML element for each feature
      //     var el = document.createElement('div');
      //     el.className = 'marker';
      //     el.id="markerID";

      //     // make a marker for each feature and add to the map
      //     new mapboxgl.Marker(el)
      //       .setLngLat(marker.geometry.coordinates)
      //       .addTo(map.current);
      //   });

      // Add a circle layer
      map.current.addLayer({
        id: 'circle',
        type: 'circle',
        source: 'points',
        paint: {
          'circle-color': '#5C5C5B',
          'circle-radius': 11,
          'circle-stroke-width': 5,
          'circle-stroke-color': '#ffffff',
        },
      });

      //    // Add a circle layer
      //    map.current.addLayer({
      //     'id': 'points',
      //     'type': 'symbol',
      //     'source': 'points',
      //     'layout': {
      //         'marker-url': 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1724px-Apple_logo_black.svg.png',

      //     }
      // });
      map.current.addControl(new mapboxgl.NavigationControl());

      map.current.on('click', (/* e */) => {
        if (mapCardRef.current.style.display === 'block') {
          mapCardRef.current.style.display = 'none';
          if (prev.length > 0) {
            map.current.flyTo({
              center: prev,
            });
          }
          prev = [];
        }
      });
      // Center the map on the coordinates of any clicked circle from the 'circle' layer.
      map.current.on('click', 'circle', (e) => {
       
        prev = [
          e.features[0].geometry.coordinates[0],
          e.features[0].geometry.coordinates[1],
        ];

        map.current.flyTo({
          center: [
            e.features[0].geometry.coordinates[0] - 0.6,
            e.features[0].geometry.coordinates[1],
          ],
        });
        setMapCard({
          subTitle: e.features[0].properties.subTitle,
          btnLabel: e.features[0].properties.btnLabel,
          footerIcon: e.features[0].properties.footerIcon,
          footerCard: e.features[0].properties.footerCard,
          title: e.features[0].properties.title,
          description: e.features[0].properties.description,
          url: e.features[0].properties.url,
          // manca l'icon, creare un oggetto inveci di tutto questo blocco
          color: e.features[0].properties.color,
          slug: e.features[0].properties.slug,
        });
        mapCardRef.current.style.display = 'block';
      });

      // Change the cursor to a pointer when the it enters a feature in the 'circle' layer.
      map.current.on('mouseenter', 'circle', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      map.current.on('mouseleave', 'circle', () => {
        map.current.getCanvas().style.cursor = '';
      });
    });
  });

  return (
    <div id="cm" style={{ width: '100%', height: 510, marginTop: 20 }}>
      <div ref={mapCardRef} id="mapCard">
        <CardMap fields={mapCard} />
      </div>
      <div ref={mapContainer} id="mp" className="map-container" />
    </div>
  );
};

export default Mappa;
