import React, { useEffect, useState } from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './style.css';

const useStyles = makeStyles(() => ({
  root: {
    margin: ({ breakpoints }) => (!breakpoints.sm ? 20 : 10),
    maxWidth: ({ breakpoints }) => (!breakpoints.sm ? 380 : 280),
    maxHeight: ({ breakpoints }) => (!breakpoints.sm ? 450 : 350),
  },

  btn: {
    paddingLeft: 12.5,
    textTransform: 'uppercase',
    color: ({ color }) => color,
    background: 'rgba(92,92,91,0.04)',
  },
}));

export default function CardMap({ fields }) {
  const intl = useIntl();
  const breakpoints = useBreakpoint();
  const [icons, setIcons] = useState([]);

  const classes = useStyles({ color: fields.color, breakpoints });

  useEffect(() => {
    const iconRow = fields.footerIcon ? fields.footerIcon.substring(1, fields.footerIcon.length - 1).split(',') : [];
    setIcons(iconRow);
  }, [fields.footerIcon]);

  return (
    <Card className={classes.root}>
      <Link to={fields.slug}>
        <CardMedia
          className={classes.media}
          style={{ height: !breakpoints.sm ? 180 : 105 }}
          image={fields.url}
          title="Contemplative Reptile"
        />
      </Link>
      <CardContent>
        <div className="cardMapTitle">
          {fields.title && <span dangerouslySetInnerHTML={{ __html: fields.title }} />}
        </div>
        <div className="cardMapDesc">
          {fields.description}
        </div>
      </CardContent>

      <CardActions>
        <Link to={fields.slug}>
          <Button className={classes.btn} size="small" color="primary">
            {intl.formatMessage({ id: 'btn_leggi_tutto' })}
            <KeyboardArrowRightIcon />
          </Button>
        </Link>

      </CardActions>
      {fields.footerCard && (
      <div className="cardMapFooter" style={{ backgroundColor: fields.color }}>

        <div style={{
          height: 30, padding: '7.4px 20px 7.4px 8px', display: 'flex', flexDirection: 'row',
        }}
        >
          {/* {fields.footerIcon &&<img alt="iconCardIMG" src={fields.footerIcon} className="cardMapIMG"/>} */}
          { icons.filter((ic) => !!ic).map((icon, index) => {
            const subIcon = icon.substring(1, icon.length - 1);
            return (
              <img
                alt="iconCardIMG"
                src={subIcon}
                className="cardMapIMG"
                key={index}
                style={{ paddingRight: 7, paddingLeft: 7, borderRight: index < icons.length - 1 ? '1px solid white' : 0 }}
              />
            );
          })}

        </div>

        <div className="cardMapFooterTxt">
          {fields.footerCard}
        </div>

      </div>
      )}

    </Card>
  );
}
